@import "../settings/settings.globals";
@import "@/scss/tools/tools.responsive-grid.scss";

// resetButton()
//
// Removea all styles from a button
//
// Styleguide Tools.mixins.resetButton

@mixin resetButton() {
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  text-transform: none;
  font-variant: normal;
  outline: 0;
  border-radius: 0;
  height: auto;
  &:focus {
    outline: 0;
  }
}

@mixin setNewFont() {
  font-family: $next, "helvetica", "helvetica neue", "arial", sans-serif !important;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: "ss01"; /*alternates - stylistic set 01 in DIN Next*/
  text-align: left;
}

// Must be included on all pages of NEW WEB DESIGN.
// To be deprecated when old design is removed totally
@mixin insertNewFont() {
  @include setNewFont();

  input,
  button {
    @include setNewFont();
  }
}

@mixin no-font-spacing($font-size) {
  & .no-font-spacing,
  &.no-font-spacing {
    line-height: rem($font-size);
    .apple-device & {
      &::before {
        margin-bottom: $font-size * -0.1;
      }

      &::after {
        margin-bottom: $font-size * -0.22;
      }
    }
    &::before {
      margin-bottom: $font-size * -0.17;
    }

    &::after {
      margin-top: $font-size * -0.16;
    }

    &::before,
    &::after {
      content: "";
      display: block;
      height: 0;
      width: 0;
    }
  }
}
@mixin font-spacing-responsive($size: body, $font-stack: $font-size-stack) {
  $sizemap: map-get($font-stack, $size);
  @if $sizemap {
    $breakpoint-values: map-get($sizemap, xs);
    @if $breakpoint-values {
      @include no-font-spacing(map-get($breakpoint-values, font-size));
    }
    $breakpoint-values: map-get($sizemap, md);
    @if $breakpoint-values {
      @include media-breakpoint-up(lg) {
        @include no-font-spacing(map-get(map-get($sizemap, md), font-size));
      }
    }
  }
}

@mixin font-size($size: body, $bp: xs, $font-stack: $font-size-stack) {
  $sizemap: map-get($font-stack, $size);

  @if $sizemap {
    $breakpoint-values: map-get($sizemap, $bp);
    @if $breakpoint-values {
      font-size: rem(map-get($breakpoint-values, font-size));
      line-height: rem(map-get($breakpoint-values, line-height));
    }
  }
}

@mixin font-weight($weight) {
  @if $weight == thin {
    font-weight: 200;
  } @else if $weight == bold {
    font-weight: 500;
  } @else {
    font-weight: 400;
  }
}

@mixin font-responsive($size, $weight: regular, $variant: normal) {
  @include font-size($size, xs);
  @include font-weight($weight);
  font-variant: $variant;

  @if ($variant == small-caps) {
    text-transform: lowercase;
  }
  @if ($variant == underline) {
    text-decoration: underline;
  }

  @include media-breakpoint-up(lg) {
    @include font-size($size, md);
  }
}

// @include font-responsive(small);
// @include font-responsive(body, bold, small-caps);

@mixin no-user-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// @include no-user-select;

@mixin pretty-scrollbars {
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-track {
    background: $white;
  }

  &::-webkit-scrollbar-thumb {
    background: $gray;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $gray-dark;
  }
}

// @include pretty-scrollbars;

@mixin no-scrollbars {
  &::-webkit-scrollbar {
    display: none;
  }
}

// @include pretty-scrollbars;
