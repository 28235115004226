﻿@import "@/scss/defaults.scss";

.main-header.-black-theme + main + .main-footer {
    background-color: $black;
    color: $gray-light;

    label,
    li,
    a,
    a:visited,
    p,
    h4 {
        color: $gray-light;
    }

    svg.brand-logo {
        fill: $gray-light;

        path {
            fill: $gray-light;
        }
    }

    select {
        color: $gray-light;
    }

    &-controls {
        hr {
            @include media-breakpoint-up(md) {
                border-color: $gray-light;
            }
        }
    }

    input {
        border-color: $gray-light;
    }
}

.main-footer {
    padding-top: 36px;
    color: $main-footer-color;
    font-size: rem(13px);
    font-weight: 300;
    line-height: rem(18px);
    background-color: $main-footer-bgcolor;
    overflow: hidden;

    label,
    li,
    a,
    a:visited,
    p,
    h4 {
        color: $main-footer-color;
        text-align: left;
        font-size: rem(13px);
        line-height: rem(24px);
    }

    p.title, h4 {
        margin-bottom: 0;
        font-weight: 500;
        font-variant: small-caps;
        text-transform: lowercase;
    }

    svg.brand-logo {
        fill: $main-footer-color;
        transform: 0;

        path {
            fill: $main-footer-color;
        }

    }
    .brand-logo--desktop {
        transform: translateY(5px) !important;
    }
    hr {
       display: block;
        height: 1px;
        margin: 0;
        border: 0;
        border-top: 1px solid $main-footer-color;
    }

    ul {
        padding: 0;
        list-style-type: none;
        margin-bottom: 24px;
        li {
            margin-top: 4px;
        }
    }
    &-controls {

        @include media-breakpoint-down(sm) {
            @include media-breakpoint-up(md) {
                padding-top: 36px;
                padding-bottom: 18px;
            }
            margin: 0;
            > div {
                padding: 0;
            }
        }
        .arrow {
            transform: translateY(-2px);
        }
        select {
            color: $main-footer-color !important;
            background-color: transparent;
            option {
                color: $black;
            }
        }

       
    }

    &-list {
        column-count: 2;
        @include make-column-gaps();
        margin-top: 18px;

        @include media-breakpoint-up(sm) {
            column-count: 2;
        }

        @include media-breakpoint-up(md) {
            margin-top: 0;
            column-count: 2;
        }

        @include media-breakpoint-up(lg) {
            column-count: 4;
        }

        > div {
            display: block;
            break-inside: avoid;
            width: 100%;

            a {
                //font-weight: 300;
            }

                   }
    }

    &-newsletter-section {
        .footer-breadcrumb {
            margin-bottom: 36px;
        }
    }


    .fontello, .fontello::before {
        font-size: rem(13px);
    }
}
.main-footer-bottom-footer {
    font-size: rem(11px);
}
