﻿@import "@/scss/defaults.scss";



$background: $black;
$border: transparent;
$border-radius: 6px;
$color: $white;
$font-size: 18px;
$line-height: 14px;
$padding-x: 9px;
$padding-y: 9px;

.tinymce-button a {
    @include font-responsive(body, regular);
    text-decoration: none!important;
    white-space: nowrap;
    display: inline-block;
    width: auto;
    margin-right: 18px;
    font-variant: normal;
    text-transform: none;
    pointer-events: all;
    border: 1px solid $black;
    text-align: center!important;
    padding: 9px 9px;
    border-radius: 6px;
    line-height: 1.375rem!important;
    height: 42px;
    &:hover {
        background-color: $black;
        border-color: $black;
        color: $white !important;
    }
}


// .tinymce-button a,
// a.btn,
// input.btn,
button.-stripped {
    @include resetButton();
    width: auto;
    transform: none !important;
    &:focus {
        outline: 0 !important;
        box-shadow: none !important;

        .user-is-tabbing & {
            box-shadow: $focusTabbing;
        }
    }
}
button.btn {
    @include font-responsive(body, regular);
    border-width: 1px;
    border-style: solid;
    transition: all .25s;
    width: 100%;
    text-align: center !important;
    white-space: nowrap;

    @include button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius);
    line-height: rem(22px) !important;
    height: 42px;

    &.-stripped {
        @include resetButton();
        width: auto;
        transform: none !important;
    }

    &.-block {
        display: block;
    }

    &.-inline {
        padding-left: 18px;
        padding-right: 18px;
        display: inline-block;
        width: auto;

        @include media-breakpoint-up(lg) {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    &.-font-small-caps {
        @include font-responsive(body, regular, small-caps);
        text-transform: lowercase;
    }

    &.-main {
        @include button-variant($blue, $border, $hover-background: darken($blue, 7.5%), $hover-border: darken($border, 7.5%), $active-background: darken($blue, 7.5%), $active-border: darken($border, 7.5%));
        color: $color;

        &:active {
            color: $white !important; /*TODO can this be removed*/
        }

        &:hover {
            color: $white;
        }

        &.-ghost {
            background-color: transparent;
            border-color: $blue;
            color: $blue;

            &:active {
                background-color: $blue;
            }
        }
    }

    &.-primary {
        @include button-variant($black, $black, $hover-background: lighten($black, 7.5%), $hover-border: darken($black, 7.5%), $active-background: lighten($black, 7.5%), $active-border: lighten($black, 7.5%));

        .main-header.-black-theme + main &, .-black-theme &, &.-inverted {
            @include button-variant($white, $white, $hover-background: darken($white, 7.5%), $hover-border: darken($white, 7.5%), $active-background: darken($white, 7.5%), $active-border: darken($white, 7.5%));
        }
    }

    &.-secondary {
        @include button-outline-variant($black, $color-hover: color-yiq($color), $active-background: $color, $active-border: $white);

        &:hover {
            svg {
                path:nth-child(2) {
                    fill: $white;
                }
            }
        }

        .main-header.-black-theme + main &, .-black-theme &, &.-inverted {
            @include button-outline-variant($white, $color-hover: color-yiq($white), $active-background: $white, $active-border: $white);

            &:hover {
                svg {
                    path:nth-child(2) {
                        fill: $black;
                    }
                }
            }
        }
    }

    &.-small {
        height: 32px;
        font-size: rem(13px);
        line-height: rem(18px) !important;
        padding-top: 6px;
        padding-bottom: 6px;
    }

    &.-disabled {
        background-color: $gray;
        border-color: $gray;
        color: $gray-dark;
        cursor: default;

        &:hover {
            background-color: $gray !important;
            color: $gray-dark !important;
            animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
            transform: translate3d(0, 0, 0);
            backface-visibility: hidden;
            perspective: 1000px;
        }
    }

    &:active {
        transform: scale(0.9);
    }

    &:focus {
        outline: 0 !important;
        box-shadow: none !important;

        .user-is-tabbing & {
            box-shadow: $focusTabbing;
        }
    }

    .icon,
    .icon path {
        fill: $black;
    }

    &:not(.-stripped):hover {

        .icon,
        .icon path {
            fill: $white;
        }
    }
}
