﻿:root {
    --header-height: 60px;
    --header-usp-height: 48px;
    --header-b2buser-height: 60px;
    --transparent-header-herotop-position: 78px;
    --transparent-header-herotop-position-lg: 120px;
}

.main-header {
    &.-transparent-header-theme {
        + * {
            --header-height: 0;
        }
       ~ * {
            
            .block-wrapper div:first-of-type {
                .video-hero-block .overlay-text {

                    top: 78px !important;
                    @include media-breakpoint-up(lg) {
                        top: 120px !important;
                    }
                }
                .herotop .overlay-text.-top {
                    top: var(--transparent-header-herotop-position) !important;
                    @include media-breakpoint-up(lg) {
                        top: var(--transparent-header-herotop-position-lg) !important;
                    }
                }
            }
        }
    }

    + * {
        padding-top:  var(--header-height);
    
        @include media-breakpoint-up(lg) {
            .-usp-visible.view-uspbar & {
                padding-top: calc(var(--header-usp-height) + var(--header-height));
            }
            
        }
     }

     &.-b2b-userbar ~ main,
     &.-b2b-userbar ~ div.new-grid {
        padding-top: calc(var(--header-b2buser-height));

    }

    &.-signup-bar {
        ~ .new-grid {
            .block-wrapper div:first-of-type {
                .video-hero-block {
                    margin-top: 60px;
                    // .overlay-text.-top {
                    //     top: var(--transparent-header-herotop-position) !important;
                    //     @include media-breakpoint-up(lg) {
                    //         top: var(--transparent-header-herotop-position-lg) !important;
                    //     }
                    // } 
                }
            }
        }

    }

     
}


// hide on checkout page
#vueSiteHeader + .vue-portal-target.notification-portal {
    display: none;
}

main {
    &.-page-spacing {
        padding-bottom: 60px;
        @include media-breakpoint-up(md) {
            padding-bottom: 120px;
        }
    }
}
