﻿// Cart total
//
// Markup: components.cart-total.hbs
//
// Styleguide Components.molecule.cart-total
.cart-total {
    @include make-row();
    margin-top: -1px;
    font-size: rem(13px);
    font-weight: 500;
    line-height: rem(18px);
    color: $black;

    > div {
        @include make-col-ready();
        @include make-col(12);
    }

    &-value,
    &-label {
        @include make-col-ready();
        @include make-col(6);
    }

    &-value {
        text-align: right;
    }

    &-checkout {
        @include make-col-ready();
        @include make-col(10);
        padding: 18px 0;
    }
}
