// example: margin-top: 6px = m-t-6

$thespacers: (3, 6, 9, 12, 15, 18, 21, 24, 27, 36, 42, 48, 60, 72, 0);

@mixin space($abbrev, $size, $prop, $breakpoint) {
  $length: #{$size}px;
  .#{$abbrev}-a-#{$breakpoint}#{$size} { #{$prop}:        $length $length !important; } // a = All sides
  .#{$abbrev}-t-#{$breakpoint}#{$size} { #{$prop}-top:    $length !important; }
  .#{$abbrev}-r-#{$breakpoint}#{$size} { #{$prop}-right:  $length !important; }
  .#{$abbrev}-b-#{$breakpoint}#{$size} { #{$prop}-bottom: $length !important; }
  .#{$abbrev}-l-#{$breakpoint}#{$size} { #{$prop}-left:   $length !important; }

  // Axes
  .#{$abbrev}-x-#{$breakpoint}-#{$size} {
    #{$prop}-right:  $length !important;
    #{$prop}-left:   $length !important;
  }
  .#{$abbrev}-y-#{$breakpoint}-#{$size} {
    #{$prop}-top:    $length !important;
    #{$prop}-bottom: $length !important;
  }
}



