@import "@/scss/defaults.scss";

// Input
//
// .-main   - Use this class for default input
//
// Styleguide Elements.atom.input


$border-radius: 6px;

textarea:focus {
    outline: 0 !important;
    box-shadow: none !important;

    .user-is-tabbing & {
        box-shadow: $focusTabbing;
    }
}

input {

    &.-main {
        @include font-responsive(body);
        height: rem(42px);
        padding: 0 18px;
        color: $black;
        background-color: $white;
        border: 1px solid $black;
        border-radius: $border-radius;

        &::placeholder {
            color: $gray-dark;
        }
    }

    &.-w100 {
        width: 100%;
    }

    &.-disabled {
        color: $gray-dark;
        user-select: none;
        pointer-events: none;
    }
}

label {
    &.-main {
        display: block;
        @include font-responsive(small);
        margin-top: 18px;
    }
}

.input {

    &-fileinput {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        border: 0;
    }

    &-fileupload {
        width: 100%;
        border: 1px dashed $gray-dark;
        border-radius: 6px;
        padding-left: 18px;
        padding-right: 18px;

        .errorInput {
            border-color: $red;
        }
    }

    &-fileupload-mobile {
        margin-top: 9px;
    }
}

input[type="file"] {
    display: block;
    margin: 0 auto;        
}


