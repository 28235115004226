@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size in $thespacers {
    @include space($abbrev, $size, $prop, "");
    @include media-breakpoint-up(sm) {
      @include space($abbrev, $size, $prop, "sm-");
    }
    @include media-breakpoint-up(md) {
      @include space($abbrev, $size, $prop, "md-");
    }
    @include media-breakpoint-up(lg) {
      @include space($abbrev, $size, $prop, "lg-");
    }
    @include media-breakpoint-up(xl) {
      @include space($abbrev, $size, $prop, "xl-");
    }
  }
}

.stripped-btn {
  @include resetButton();
  width: auto;
  transform: none;
}
.section-spacer {
  margin-bottom: 42px;
  @include media-breakpoint-up(md) {
    margin-bottom: 60px;
  }
}

.-user-select-none {
  user-select: none;
}
.-small-caps {
  font-variant: small-caps !important;
  text-transform: lowercase;
}
.-break-word {
  word-break: break-word;
}
.-nowrap {
  white-space: nowrap;
}
.-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.-underline {
  text-decoration: underline;
  &-hover {
    &:hover {
      text-decoration: underline;
    }
  }
}
.-strikeout {
  text-decoration: line-through;
}
.-no-pointer-event {
  pointer-events: none;
}
.-p-relative {
  position: relative;
}
.-p-absolute {
  position: absolute;
}

.-text-right {
  text-align: right;
}

.-text-left {
  text-align: left;
}

.no-scrollbar {
  @include no-scrollbars;
}
.pretty-scrollbars {
  @include pretty-scrollbars;
}
.div {
  display: block;
}
.font-responsive-smallest {
  @include font-responsive(smallest);
}

.font-responsive-small {
  @include font-responsive(small);
  @include font-spacing-responsive(small);
}

.font-body-xs {
  @include font-size(body, xs);
  @include font-spacing-responsive(body);
}
.font-body-md {
  @include font-size(body, md);
  @include font-spacing-responsive(body);
}

.font-responsive-md-small {
  @include media-breakpoint-up(md) {
    @include font-size(small, xs);
    @include font-spacing-responsive(small);
  }
}

.font-responsive-body {
  @include font-responsive(body);
  @include font-spacing-responsive(body);
}
.font-responsive-md-body {
  @include media-breakpoint-up(md) {
    @include font-size(body, md);
  }
}

.font-responsive-lg-body {
  @include media-breakpoint-up(lg) {
    @include font-size(body, md);
  }
}

.font-responsive-large {
  @include font-responsive(large);
}
.font-responsive-md-large {
  @include media-breakpoint-up(md) {
    @include font-size(large, xs);
  }
}
.font-responsive-lg-large {
  @include media-breakpoint-up(lg) {
    @include font-size(large, md);
  }
}
.font-responsive-larger {
  @include font-responsive(larger);
}
.font-responsive-lg-larger {
  @include media-breakpoint-up(lg) {
    @include font-size(larger, md);
  }
}

.font-responsive-xl {
  @include font-responsive(xl);
}
.font-responsive-lg-xl {
  @include media-breakpoint-up(lg) {
    @include font-size(xl, md);
  }
}


.font-responsive-2xl {
  @include font-responsive(2xl);
}

b, strong,
.-strong,
.-bold {
  font-weight: 500 !important;
}
.-thin {
  font-weight: 200 !important;
}
.-linethrough {
  text-decoration: line-through;
}

.-small-text {
  @include font-responsive(small, regular);
}

.-small-link {
  @include font-responsive(small, regular, underline);
}

.small-caps {
  font-variant: small-caps !important;
  text-transform: lowercase;
}

.v-lazy-image {
  opacity: 0;
  transition: opacity 0.33s;
  &.v-lazy-image-loaded {
    opacity: 1;
  }
}

.apple-device .apple-4 {
  margin-top: 4px;
}

.apple-device .apple-3 {
  margin-top: 3px;
}

.apple-device .apple-2 {
  margin-top: 2px;
}

.apple-device .apple-1 {
  margin-top: 1px;
}

.frac {
  font-variant-numeric: diagonal-fractions;
  -moz-font-feature-settings: 'frac';
  -webkit-font-feature-settings: 'frac';
  font-feature-settings: 'frac' on;
}