/// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-container(
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      padding-right: $gutter;
      padding-left: $gutter;
    }
  }

  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-max-widths(
  $max-widths: $container-max-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row(
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  display: flex;
  flex-wrap: wrap;

  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      margin-right: -$gutter / 2;
      margin-left: -$gutter / 2;
    }
  }
}

@mixin make-col-ready(
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  position: relative;
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we use `flex` values
  // later on to override this initial width.
  width: 100%;

  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }
  }
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // Add a `max-width` to ensure content within each column does not blow out
  // the width of the column. Applies to IE10+ and Firefox. Chrome and Safari
  // do not appear to require this.
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}

@mixin make-column-gaps(
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      column-gap: $gutter;
    }
  }
}

// custom
@mixin make-margin-x(
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      margin-right: $gutter;
      margin-left: $gutter;
    }
  }
}

// custom
@mixin make-negative-margin-x(
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      margin-right: -$gutter;
      margin-left: -$gutter;
    }
  }
}

// custom
@mixin make-padding-x(
  $divident: 1,
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      padding-right: $gutter/$divident;
      padding-left: $gutter/$divident;
    }
  }
}

@mixin make-full-padding-x(
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      padding-right: $gutter;
      padding-left: $gutter;
    }
  }
}

@mixin make-column-gutters(
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
    }
  }
}

// CSS Grid

@mixin make-grid-gaps(
  $gutters: $grid-gutter-widths,
  $breakpoints: $grid-breakpoints
) {
  @each $breakpoint, $gutter in $gutters {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      grid-column-gap: $gutter;
    }
  }

  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@mixin make-css-grid($columns: 12) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);
  @include make-container();
  @include make-container-max-widths();
  @include make-grid-gaps();
}

@mixin make-inner-css-grid($columns: 12) {
  display: grid;
  grid-template-columns: repeat($columns, 1fr);

  @include make-grid-gaps();
}

@mixin make-default-grid-element($columns: 12) {
  grid-column-end: span $columns;
  min-width: 0;
  min-height: 0;
  margin: 0;
  max-width: 100%;
}
