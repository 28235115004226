/**
 * @license
 * MyFonts Webfont Build ID 3781351, 2019-07-04T07:49:14-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: DINNextLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/din-next/pro-light/
 * 
 * Webfont: DINNextLTPro-Regular by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/din-next/pro-regular/
 * 
 * Webfont: DINNextLTPro-Medium by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/din-next/pro-medium/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3781351
 * Licensed pageviews: 50,000,000
 * Webfonts copyright: Copyright &#x00A9; 2010 Linotype GmbH, www.linotype.com. All rights reserved. This font software may not be reproduced, modified, disclosed or transferred without the express written approval of Linotype GmbH.
 * 
 * © 2019 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
/*@import url("//hello.myfonts.net/count/39b2e7");*/

@font-face {
    font-family: "DIN Next Pro";
    src: url("fonts/39B2E7_0_0.eot");
    src: url("fonts/39B2E7_0_0.eot?#iefix") format("embedded-opentype"), url("fonts/39B2E7_0_0.woff2") format("woff2"), url("fonts/39B2E7_0_0.woff") format("woff"), url("fonts/39B2E7_0_0.ttf") format("truetype"), url("fonts/39B2E7_0_0.svg#wf") format("svg");
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: "DIN Next Pro";
    src: url("fonts/39B2E7_1_0.eot");
    src: url("fonts/39B2E7_1_0.eot?#iefix") format("embedded-opentype"), url("fonts/39B2E7_1_0.woff2") format("woff2"), url("fonts/39B2E7_1_0.woff") format("woff"), url("fonts/39B2E7_1_0.ttf") format("truetype"), url("fonts/39B2E7_1_0.svg#wf") format("svg");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "DIN Next Pro";
    src: url("fonts/39B2E7_2_0.eot");
    src: url("fonts/39B2E7_2_0.eot?#iefix") format("embedded-opentype"), url("fonts/39B2E7_2_0.woff2") format("woff2"), url("fonts/39B2E7_2_0.woff") format("woff"), url("fonts/39B2E7_2_0.ttf") format("truetype"), url("fonts/39B2E7_2_0.svg#wf") format("svg");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    src: url("https://res.cloudinary.com/norrona/raw/upload/v1484038386/Fonts/fontello.eot") format("embedded-opentype"), url("https://res.cloudinary.com/norrona/raw/upload/v1484038386/Fonts/fontello.woff2") format("woff2"), url("https://res.cloudinary.com/norrona/raw/upload/v1484038386/Fonts/fontello.woff") format("woff"), url("https://res.cloudinary.com/norrona/raw/upload/v1484038386/Fonts/fontello.ttf") format("truetype"), url("https://res.cloudinary.com/norrona/raw/upload/v1484038386/Fonts/fontello.svg") format("svg");
    font-display: swap;
}


.icon-tiktok {
    position: relative;
    padding-left: 21px;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        background-repeat: no-repeat;
        background-image: url("https://res.cloudinary.com/norrona/image/upload/v1696503121/Icons/svg/social/tiktok-1_gray.svg");
    }
    
}

.fontello {
  margin-right: .5rem;
  font-size: 2rem;
  text-decoration: none;
  border-bottom: 0 !important;

  &[class^="icon-"]::before,
  &[class*=" icon-"]::before {
      display: inline-block;
      width: 1em;
      margin-right: .5em;
      font-family: "fontello";
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      line-height: 1em;
      text-align: center;
      text-decoration: none;
      text-indent: 0;
      text-transform: none;
      border-bottom: 0;
      speak: none;
      // -webkit-font-smoothing: antialiased;
      // -moz-osx-font-smoothing: grayscale;
  }

  &.icon-star::before {
      content: "\e800";
  }

  &.icon-star-empty::before {
      content: "\e801";
  }

  &.icon-star-green {
      color: #82a731;
  }

  &.icon-star-half::before {
      content: "\e802";
  }

  &.icon-search::before {
      content: "\e803";
  }

  &.icon-menu::before {
      top: 0;
      width: 1.5625rem;
      height: 1.5625rem;
      content: "\f0c9";

      .experience & {
          color: #fff;
      }
  }

  &.icon-cancel::before {
      content: "\e804";
  }

  &.icon-twitter::before {
      content: "\f099";
  }

  &.icon-facebook::before {
      content: "\f09a";
  }

  &.icon-linkedin::before {
      content: "\f0e1";
  }

  &.icon-star-half-alt::before {
      content: "\f123";
  }

  &.icon-youtube::before,
  &.icon-youtube::before {
      content: "\f16a";
  }

  &.icon-instagram::before {
      content: "\f16d";
  }

  &.icon-down-open::before {
      content: "\e805";
  }

  &.icon-left-open::before,
  &.icon-arr-single-left::before {
      content: "\e806";
  }

  &.icon-right-open::before,
  &.icon-arr-single-right::before {
      content: "\e807";
  }

  &.icon-up-open::before {
      content: "\e808";
  }

  &.icon-left::before {
      content: "\f177";
  }

  &.icon-right::before {
      content: "\f178";
  }

  &.icon-trash::before {
      content: "\f1f8";
  }

  &.icon-pinterest::before {
      content: "\f231";
  }

  &.icon-vimeo::before {
      content: "\f27d";
  }

  &.icon-down-open::before,
  &.icon-pil::before {
      content: "\e805";
  }

}
