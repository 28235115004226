.-black {
    color: $black !important;
}

.-white {
    color: $white !important;

    option {
        color: $black !important;
    }
}
.-green {
    color: $green;
    &.-clickable:hover {
        color: $green-dark;
    }
}
.-green-dark {
    color: $green-dark;
}
.-red {
    color: $red;

}
.-gray {
    color: $gray;
}
.-gray-light {
    color: $gray-light;
}
.-gray-dark {
    color: $gray-dark;
}
.-blue {
    color: $blue;
}
.-blue-light {
    color: $blue-light;
    &:hover {
        color: $blue-light;
    }
}
.-blue-hover {
    @include media-breakpoint-up(md) {
        transition: color 0.33s;
        &:hover {
            color: $blue;
        }
    }
}

.-blue-sm-WCAG {
    color: #0067F5 !important;
}
.-error {
    color: $red;
}