﻿@import "@/scss/defaults.scss";

.site-nav-cartwrapper {
  @include make-col-ready();
  @include make-col(12);
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    padding-left: 36px;
    padding-right: 36px;
  }

  > div {
    width: 100%;
    background-color: $white;
  }

  > ul {
    padding-left: 0;
    @include make-row();
    @include make-padding-x(2);
    list-style-type: none;
    background-color: $white;

    @include media-breakpoint-up(lg) {
      padding: 0;
    }

    > li {
      @include make-col-ready();
      @include make-col(12);
      padding-top: $main-navigation-drawer-padding;
      @include media-breakpoint-up(md) {
        @include make-col(8);
        .grid-checkout & {
          @include make-col(12);
        }
        margin-left: auto;
        &.cart-footer {
          @include make-col(12);
        }
      }
      @include media-breakpoint-up(lg) {
        @include make-col(12);
      }
      > div {
        &::after {
          display: block;
          content: "";
          width: 100%;
          padding-bottom: $main-navigation-drawer-padding;
          margin: 0 9px;
          border-bottom: $main-navigatiom-drawer-border;
        }
      }

      &:last-child {
        > div::after {
          content: none;
        }
      }
    }
  }
}
