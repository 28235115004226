@import "@/scss/settings/settings.colors.scss";

$menu-anim-speed: 0.22s;

$enable-grid-classes: false;

$bar-height: rem(60px);
$bar-height-md: rem(48px);
$bar-height-sm: rem(36px);
$header-height: $bar-height;
$usp-bar-height: rem(108px);


// Unit increments


$unit: 3px;

$unit-1: $unit * 1;
$unit-2: $unit * 2;
$unit-3: $unit * 3;
$unit-4: $unit * 4;
$unit-5: $unit * 5;
$unit-6: $unit * 6;
$unit-7: $unit * 7;
$unit-8: $unit * 8;
$unit-9: $unit * 9;
$unit-10: $unit * 10;
$unit-11: $unit * 11;
$unit-12: $unit * 12;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px );


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: ( sm: 768px, md: 992px, lg: 1199px, xl: 1332px );


//Spacers
// $spacer: 18px;
// $spacers: ();
// // stylelint-disable-next-line scss/dollar-variable-default
// $spacers: map-merge( ( 0: 0, 1: ($spacer * .333333), 2: ($spacer * .66667), 3: $spacer, 4: ($spacer * 1.33333), 5: ($spacer * 2) ), $spacers );


// Gutters
$grid-gutter-width-new: 36px;
$grid-gutter-width-lg: $grid-gutter-width-new;
$grid-gutter-width-md: 24px;
$grid-gutter-width-sm: 18px;
$grid-gutter-widths: ( xs: 18px, sm: 18px, md: 24px, lg:$grid-gutter-width-new, xl: $grid-gutter-width-new );

$max-width: map-get($container-max-widths, xl) - $grid-gutter-width-new;



// Font-settings
$next: 'DIN Next Pro';
//$xlite: 100;
$lite: 200;
$reg: 400;
$bold: 500;
//$bolder: 700;
//$heavy: 900;


$focusTabbing: 0px 0px 3px 2px $blue !important;

// $font-family-base: "DIN Next Pro", "helvetica", "helvetica neue", "arial", sans-serif !important;

$font-size-stack: ( 
    smallest: ( 
        xs: (
            font-size: 11px,
            line-height: 15px,
        )
    ), 
    small: ( 
        xs: (
            font-size: 13px,
            line-height: 18px,
        )
    ), 
    body: ( 
        xs: (
            font-size: 16px,
            line-height: 21px,
        ),
        md: (
            font-size: 18px,
            line-height: 24px
        )
    ), 
    large: ( 
        xs: (
            font-size: 21px,
            line-height: 27px,
        ),
        md: (
            font-size: 24px,
            line-height: 30px
        )
    ), 
    larger: ( 
        xs: (
            font-size: 24px,
            line-height: 30px,
        ),
        md: (
            font-size: 30px,
            line-height: 36px
        )
    ), 
    xl: ( 
        xs: (
            font-size: 30px,
            line-height: 36px,
        ),
        md: (
            font-size: 42px,
            line-height: 48px,
        )
    ), 
    2xl: ( 
        xs: (
            font-size: 53px,
            line-height: 60px,
        ),
        md: (
            font-size: 53px,
            line-height: 60px,
        )
    ) 
    // 3xl: ( 
    //     xs: (
    //         font-size: 53px,
    //         line-height: 60px,
    //     ),
    //     md: (
    //         font-size: 69px,
    //         line-height: 72px
    //     )
    // ), 
    // 4xl: ( 
    //     xs: (
    //         font-size: 53px,
    //         line-height: 60px,
    //     ),
    //     md: (
    //         font-size: 80px,
    //         line-height: 84px
    //     )
    // ), 
    // 5xl: ( 
    //     xs: (
    //         font-size: 53px,
    //         line-height: 60px,
    //     ),
    //     md: (
    //         font-size: 92px,
    //         line-height: 96px
    //     )
    // )
);


