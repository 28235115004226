﻿@import "@/scss/defaults.scss";

.tinymce-link {
    @include font-responsive(body);
    display: block;
    margin-top: 9px;
    margin-bottom: 9px;

    p {
        margin: 0;
        padding: 0;
        display: inline;

        a:hover {
            text-decoration: underline !important;
        }
    }

    a {
        text-decoration: none !important;
        white-space: nowrap;
        text-transform: none;
    }

    a::after {
        padding-left: 3px;
        display: inline-block;
        -webkit-transform: translateY(2px);
        content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY29ucy9hcnJvdy9zbWFsbC9yaWdodCBsaWdodDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZyBpZD0iaWNvbnMvYXJyb3cvc21hbGwvcmlnaHQtbGlnaHQiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPHBvbHlnb24gaWQ9ImJnIiBwb2ludHM9IjAgMCAxNiAwIDE2IDE2IDAgMTYiPjwvcG9seWdvbj4gICAgICAgIDxwb2x5Z29uIGlkPSJpY29uIiBmaWxsPSIjMUQxRDFEIiBwb2ludHM9IjEwIDMuOTk5NSA4LjkzOSA1LjA2MTUgMTEuMTI5IDcuMjQ5NSAyIDcuMjQ5NSAyIDguNzUwNSAxMS4xMjkgOC43NTA1IDguOTM5IDEwLjkzODUgMTAgMTEuOTk5NSAxNCA3Ljk5OTUiPjwvcG9seWdvbj4gICAgPC9nPjwvc3ZnPg==);
    }

    &--reverse {
        a::before {
            padding-right: 3px;
            display: inline-block;
            -webkit-transform: translateY(2px);
            content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY29ucy9hcnJvdy9zbWFsbC9sZWZ0IGxpZ2h0PC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJpY29ucy9hcnJvdy9zbWFsbC9sZWZ0LWxpZ2h0IiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxnIGlkPSJpY29ucy9hcnJvdy9zbWFsbC9yaWdodC1saWdodCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOC4wMDAwMDAsIDguMDAwMDAwKSByb3RhdGUoMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC04LjAwMDAwMCwgLTguMDAwMDAwKSAiPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJiZyIgcG9pbnRzPSIwIDAgMTYgMCAxNiAxNiAwIDE2Ij48L3BvbHlnb24+ICAgICAgICAgICAgPHBvbHlnb24gaWQ9Imljb24iIGZpbGw9IiMxRDFEMUQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgcG9pbnRzPSIxMCAzLjk5OTUgOC45MzkgNS4wNjE1IDExLjEyOSA3LjI0OTUgMiA3LjI0OTUgMiA4Ljc1MDUgMTEuMTI5IDguNzUwNSA4LjkzOSAxMC45Mzg1IDEwIDExLjk5OTUgMTQgNy45OTk1Ij48L3BvbHlnb24+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
        }

        a::after {
            content: '';
        }
    }

    &--small {
        @include font-responsive(small);

        a::after {
            padding-left: 3px;
            display: inline-block;
            -webkit-transform: translateY(3px);
            content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY29ucy9hcnJvdy9zbWFsbC9yaWdodCB0aGluPC90aXRsZT4gICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+ICAgIDxnIGlkPSJpY29ucy9hcnJvdy9zbWFsbC9yaWdodC10aGluIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxwb2x5Z29uIGlkPSJiZyIgcG9pbnRzPSIwIDAgMTYgMCAxNiAxNiAwIDE2Ij48L3BvbHlnb24+ICAgICAgICA8cG9seWdvbiBpZD0iaWNvbiIgZmlsbD0iIzFEMUQxRCIgcG9pbnRzPSIxMCAzLjk5OTUgOS4yOTMgNC43MDY1IDEyLjA4NSA3LjQ5OTUgMiA3LjQ5OTUgMiA4LjQ5OTUgMTIuMDg1IDguNDk5NSA5LjI5MyAxMS4yOTI1IDEwIDExLjk5OTUgMTQgNy45OTk1Ij48L3BvbHlnb24+ICAgIDwvZz48L3N2Zz4=);
        }

        &--reverse {
            @include font-responsive(small);

            a::before {
                padding-right: 3px;
                display: inline-block;
                -webkit-transform: translateY(3px);
                content: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxNnB4IiBoZWlnaHQ9IjE2cHgiIHZpZXdCb3g9IjAgMCAxNiAxNiIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5pY29ucy9hcnJvdy9zbWFsbC9sZWZ0IHRoaW48L3RpdGxlPiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gICAgPGcgaWQ9Imljb25zL2Fycm93L3NtYWxsL2xlZnQtdGhpbiIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+ICAgICAgICA8ZyBpZD0iaWNvbnMvYXJyb3cvc21hbGwvcmlnaHQtdGhpbiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoOC4wMDAwMDAsIDguMDAwMDAwKSByb3RhdGUoMTgwLjAwMDAwMCkgdHJhbnNsYXRlKC04LjAwMDAwMCwgLTguMDAwMDAwKSAiPiAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJiZyIgcG9pbnRzPSIwIDAgMTYgMCAxNiAxNiAwIDE2Ij48L3BvbHlnb24+ICAgICAgICAgICAgPHBvbHlnb24gaWQ9Imljb24iIGZpbGw9IiMxRDFEMUQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgcG9pbnRzPSIxMCAzLjk5OTUgOS4yOTMgNC43MDY1IDEyLjA4NSA3LjQ5OTUgMiA3LjQ5OTUgMiA4LjQ5OTUgMTIuMDg1IDguNDk5NSA5LjI5MyAxMS4yOTI1IDEwIDExLjk5OTUgMTQgNy45OTk1Ij48L3BvbHlnb24+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=);
            }

            a::after {
                content: '';
            }
        }
    }
}