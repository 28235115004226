// Grid system
//
// Uses Boostrap 12 column default grid system<br />
// Docs - <a href="https://getbootstrap.com/docs/4.0/layout/grid/">https://getbootstrap.com/docs/4.0/layout/grid/</a>
//
// Markup: objects.grid.hbs
//
//
// Styleguide Objects.grid
// Container widths
//
// Set the container width, and override it for fixed navbars in media queries.

$enable-responsive-grid-classes: true;

@if $enable-responsive-grid-classes {
    .container-new {
        @include make-container();
        @include make-container-max-widths();

        &.-inline {
            @each $breakpoint, $gutter in $grid-gutter-widths {
                @include media-breakpoint-up($breakpoint, $grid-breakpoints) {
                    padding-right: $gutter / 2;
                    padding-left: $gutter/ 2;
                }
            }
        }
    }
}

// Fluid container
//
// Utilizes the mixin meant for fixed width containers, but with 100% width for
// fluid, full width layouts.

@if $enable-responsive-grid-classes {
    .container-fluid-new {
        @include make-container();
    }
}

// Row
//
// Rows contain and clear the floats of your columns.

@if $enable-responsive-grid-classes {
    .row-new {
        @include make-row();
    }

    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

// Columns
//
// Common styles for small and large grid columns

@if $enable-responsive-grid-classes {
    @include make-grid-columns();

    .row-new {
        > .col,
        > [class*="col-"] {
            @include make-column-gutters();
        }
    }
}