// Links
// 
// a tag
//
// Markup: elements.links.hbs
//
//
//
// Styleguide Elements.links


a:focus {
    outline: 0;
    .user-is-tabbing & {
        text-decoration: underline;
        text-decoration-color: $blue;
    }

    &.-add-focus-box {
        .user-is-tabbing & {
            //border: 1px solid $blue;
            box-shadow: $focusTabbing;
        }
    
    }
}


