$bulletColor: $black-light;
$bulletInvertedColor: $white;

ul {

    &.bullet {
        margin-left: 18px;

        li {
            list-style-type: disc;
        }
    }

    &.checklist {
        list-style-type: none;
        padding: 0;
        &.-inverted {
            color: $white;
        }

        li {
            position: relative;
            margin-left: 22px;

            &::before {
                position: absolute;
                top: 4px;
                left: -22px;
                width: 14px;
                min-width: 14px;
                height: 7px;
                margin-top: 1px;
                border: 2px solid $bulletColor;
                border-radius: 0;
                content: "";
                border-top-width: 0 !important;
                border-right-width: 0 !important;
                transform: rotate(-45deg);
            }
        }

        &.-small {
            li {
                margin-left: 16px;

                &::before {
                    top: 5px;
                    left: -16px;
                    width: 10px;
                    min-width: 10px;
                    height: 5px;
                }
            }
        }

        &.-inverted {
            li::before {
                border-color: $bulletInvertedColor;
            }
        }

        &.-green {
            li::before {
                border-color: $green;
            }
        }
        &.-gray {
            li::before {
                border-color: $gray-dark;
            }
        }

    }
    &.resetlist {
        list-style-type: none;
        padding: 0;
    }
}