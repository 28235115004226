// Nav arrow
//
// Arrow to use in accordions.<br/>
// To be used in a <code>button</code>-tag. Has closed state by default.<br/>
// If button-container has <code>[aria-expanded="true"]</code>-attribute. It will animate to open-state
//
@import "@/scss/defaults.scss";

$nav-arrow-size: 12px;
$nav-arrow-size-sm: 8px;

.arrow {
    position: relative;
    align-self: center;
    margin-right: 18px;
    margin-left: auto;

    @include media-breakpoint-down(sm) {
        right: -5px;
    }

    span {
        position: absolute;
        display: block;
        width: $nav-arrow-size;
        height: 2px;
        background-color: $main-navigation-color;
        transition: all .4s ease-in-out;
    }
    &.-inverted {
        span {
            background-color: $white !important;
        }
    }
    &.-black {
        span {
            background-color: $black-light;
        }
    }

    span.arrow-left {
        transform: translateX(#{-(sin(45deg) * $nav-arrow-size)*.5}) rotate(45deg);

        [aria-expanded="true"] & {
            transform: translateX(#{(sin(-45deg) * $nav-arrow-size) * .5}) rotate(-45deg);
        }
    }

    span.arrow-right {
        transform: translateX(#{(-(sin(-45deg) * $nav-arrow-size)* .5) - 1}) rotate(-45deg);

        [aria-expanded="true"] & {
            transform: translateX(#{((sin(45deg) * $nav-arrow-size)*.5) - 1}) rotate(45deg);
        }
    }

    &.-small {
        span {
            width: $nav-arrow-size-sm;
            height: 1.5px;
        }

        span.arrow-left {
            transform: translateX(#{-(sin(45deg) * $nav-arrow-size-sm)*.5}) rotate(45deg);

            [aria-expanded="true"] & {
                transform: translateX(#{(sin(-45deg) * $nav-arrow-size-sm) * .5}) rotate(-45deg);
            }
        }

        span.arrow-right {
            transform: translateX(#{(-(sin(-45deg) * $nav-arrow-size-sm)* .5) - 1}) rotate(-45deg);

            [aria-expanded="true"] & {
                transform: translateX(#{((sin(45deg) * $nav-arrow-size-sm)*.5) - 1}) rotate(45deg);
            }
        }
    }
}







// Search icon
//
// Search icon for use in menu<br/>
//
// Styleguide Components.atom.nav-assests
body > .main-header > nav {
    .search-icon {
        position: relative;
        align-self: center;
        margin-left: auto;
        display: block;
        width: 15px;
        height: 15px;
        margin-right: 6px;
        background: transparent;
        border: 2px solid $main-footer-color;
        border-radius: 50%;
        transform: translate(-5px, -2px);
        transition: all 111ms ease-out 0ms;

        &::before {
            position: absolute;
            display: block;
            content: '';
            background: $main-footer-color;
            top: 9px;
            right: -3px;
            width: 2px;
            height: 9px;
            transform: rotate(-45deg);
            transition: all 11ms ease-out 0ms;
        }

        &::after {
            position: absolute;
            display: block;
            content: '';
            background: $main-footer-color;
            top: 9px;
            right: -3px;
            width: 2px;
            height: 9px;
            transform: rotate(-45deg);
            transition: all 11ms ease-out 0ms;
        }
    }
}



// Close icon
//
// Close icon.<br/>
//
// Markup components.icon clos.hbs
//
// Styleguide Components.atom.icons

$icon-close-size: 20px;
.icon-close {
    cursor: pointer;
    width: $icon-close-size;
    height: $icon-close-size;

    span {
        position: absolute;
        display: block;
        width: $icon-close-size;
        height: 2px;
        background-color: $black;
        transition: all .4s ease-in-out;
    }

    span:first-child {
        transform: translateY($icon-close-size/2) rotate(45deg);
    }

    span:last-child {
        transform: translateY($icon-close-size/2) rotate(-45deg);
    }

    &.-white {
        span {
            background-color: $white;
        }
    }
}
