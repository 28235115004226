// Primary colors
// colors should be moved into the color array and the color function will replace variables

// stylelint-disable

$white: #FCFCFC;
$gray-lighter: #F5F5F5;
$gray-light: #DADADA;
$gray: #BEBEBE;
$gray-dark: #747474;
$black-light: #2C2F32;
$black: #1D1D1D;
$blackest: #000000;

$body-color: $black;
$background-color: $white;

$blue-dark: #006BFF;
$blue-light: #4fcaff;
$red-dark: #dc3545;
$red-light: #e7807b;
$green-dark: #349F74;
$green-light: #00FF99;


$blue: $blue-dark;
$red: $red-dark;
$green: $green-light;
$yellow: #fcff87;
$colors: (
    white: (
        base: $white 
    ),
    gray: (
        dark: $gray-dark,
        base: $gray,
        light: $gray-light,
        lighter: $gray-lighter
    ),
    black: (
        base: $black,
        light: $black-light
    ),
    blue: (
        dark: $blue-dark,
        light: $blue-light
    ),
    red: (
        dark: $red-dark,
        light: $red-light
    ),
    green: (
        dark: $green-dark,
        light: $green-light
    ),
    yellow: (
        base: $yellow
    )
);


// $colors: ();
// $colors: map-merge(( "blue": $blue, "red": $red, "green": $green, "gray": $gray-light, "black": $black ), $colors);

$primary: $black;
$secondary: $gray-lighter;
$danger: $red;

$theme-colors: ();
$theme-colors: map-merge(( "primary": $primary, "secondary": $secondary, "danger": $danger ), $theme-colors);



// footer
$main-footer-color: $gray;
$main-footer-bgcolor: $black-light;
