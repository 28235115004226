// .-fill {
//     &-white {
//         background-color: $white;
//     }

//     &-black {
//         background-color: $black !important;
//         &-light {
//             background-color: $black-light;
//         }
//     }
//     &-gray {
//         background-color: $gray;
//         &-lighter {
//             background-color: $gray-lighter;
//         }
//         &-light {
//             background-color: $gray-light;
//         }
//         &-dark {
//             background-color: $gray-dark;
//         }
//     }
// }

@each $color-key, $color-value in $colors {
  @each $tone-key, $tone-value in $color-value {
    @if $tone-key == base {
      .-fill-#{$color-key} {
        background-color: $tone-value !important;
      }
    } @else {
      .-fill-#{$color-key}-#{$tone-key} {
        background-color: $tone-value !important;
      }
    }
  }
}

