// Headings
//
// H1 to H3 is availible heading tags
//
// Markup: elements.headings.hbs
//
//
// Styleguide Elements.headings

@for $i from 1 through 6 {
  h#{$i} {
    font-weight: normal;
    text-align: left;
  }
}

p {
  text-align: left;
}
