.advanced-product-detail .grid,
.grid-startpage .grid,
.grid-contentpage .grid {
  padding-top: 18px;
  padding-bottom: 18px;
  @include media-breakpoint-up(md) {
    padding-top: 36px;
    padding-bottom: 36px;
  }
}

.grid {
  @include make-css-grid();

  .grid:not(.-start) {
    padding: 0;
  }

  > * {
    @include make-default-grid-element();
  }
}

$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

@mixin make-default-grid-element($columns: 12) {
  grid-column-end: span $columns;
  min-width: 0;
  min-height: 0;
  margin: 0;
  max-width: 100%;
}

// grid-span
@for $i from 1 through 12 {
  .g-#{$i}.g-#{$i} {
    grid-column-end: span $i;
    grid-column-start: unset;
  }

  .grid-#{$i}.grid-#{$i} {
    grid-column-end: span $i;
    grid-column-start: unset;
    @include make-inner-css-grid($i);
  }

  .grid-#{$i} > * {
    @include make-default-grid-element($i);
  }
}

// grid-start-span
@for $i from 1 through 12 {
  @for $j from 1 through 13-$i {
    .g-#{$i}-#{$j}.g-#{$i}-#{$j} {
      grid-column-end: span $j;
      grid-column-start: $i;
    }

    .grid-#{$i}-#{$j}.grid-#{$i}-#{$j} {
      grid-column-end: span $j;
      grid-column-start: $i;
      @include make-inner-css-grid($j);
    }

    .grid-#{$i}-#{$j} > * {
      @include make-default-grid-element($j);
    }
  }
}

// grid-breakpoint-span
@each $name, $breakpoint in $breakpoints {
  @media (min-width: $breakpoint) {
    @for $i from 1 through 12 {
      .g-#{$name}-#{$i}.g-#{$name}-#{$i} {
        grid-column-end: span $i;
        grid-column-start: unset;
      }

      .grid-#{$name}-#{$i}.grid-#{$name}-#{$i} {
        grid-column-end: span $i;
        grid-column-start: unset;
        @include make-inner-css-grid($i);
      }

      .grid-#{$name}-#{$i} > * {
        @include make-default-grid-element($i);
      }
    }

    // grid-breakpoint-start-span
    @for $i from 1 through 12 {
      @for $j from 1 through 13-$i {
        .g-#{$name}-#{$i}-#{$j}.g-#{$name}-#{$i}-#{$j} {
          grid-column-end: span $j;
          grid-column-start: $i;
        }

        .grid-#{$name}-#{$i}-#{$j}.grid-#{$name}-#{$i}-#{$j} {
          grid-column-end: span $j;
          grid-column-start: $i;
          @include make-inner-css-grid($j);
        }
        .grid-#{$name}-#{$i}-#{$j} > * {
          @include make-default-grid-element($j);
        }
      }
    }
  }
}
