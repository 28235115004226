// Calulation functions
// 
// A collections of 'random' calculation functions
//
// Styleguide Tools.functions.calculation


// rem($size)
// 
// Use always for font-size. Returns font-size in rem unit
//
// $size - Font-size in pixels
//
// Styleguide Tools.functions.calculation.rem
$base-font-size: 16;
@function rem($pixels, $context: $base-font-size) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($context)) {
        $context: $context * 1px;
    }

    @return ($pixels / $context) * 1rem;
}

@function color($color, $tone: 'base') {
    @return map-get(map-get($colors, $color), $tone);
}

// Math functions
// 
// A collections of math functions
//
// Styleguide Tools.functions.math
@import "tools.functions-math";


/// Fetch nested keys
/// @param {Map} $map - Map
/// @param {Arglist} $keys - Keys to fetch
/// @return {*}     

@function map-deep-get($map, $keys...) {
    @each $key in $keys {
        $map: map-get($map, $key);
    }
    @return $map;
}
